import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import classes from "./customerconfig.module.css";
import { API_URL } from "../../utils/constants";

export default function CustomerConfig() {
  const [types, setTypes] = useState([]);
  useEffect(() => {
    fetch(API_URL + "bp_account_group/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then(async (res) => {
        let data = await res.json();
        console.log(data);
        setTypes(data);
      })
      .catch(function (res) {
        console.log(res);
      });
  }, []);

  return (
    <div className={classes["data-container"]}>
      <h1>Configurations Status</h1>
      {types.length > 0 ? (
        <Formik
          initialValues={{
            checked: types.filter((x) => x.EXTERNIND).map((x) => x.ECC_KTOKD),
          }}
          onSubmit={(values, { setSubmitting }) => {
            let payload = [];
            values.checked.forEach((element) => {
              payload.push({ ECC_KTOKD: element, EXTERNIND: true });
            });
            types.forEach((ele) => {
              if (!values.checked.includes(ele.ECC_KTOKD)) {
                payload.push({
                  ECC_KTOKD: ele.ECC_KTOKD,
                  EXTERNIND: false,
                });
              }
            });
            fetch(API_URL + "bp_account_group_update/", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(payload),
            })
              .then(async (res) => {
                await res.json();
                setSubmitting(false);
              })
              .catch(function (res) {
                console.log(res);
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={classes["checkbox-grp"]}>
                <table className="container" style={{ overflow: "visible" }}>
                  <thead>
                    <tr className={classes.tableHeaders}>
                      <th>Internal Number</th>
                      {Object.keys(types[0])
                        .filter((x) => x !== "EXTERNIND")
                        .map((item) => {
                          return <th key={item}>{item}</th>;
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {types.map((ele) => {
                      return (
                        <tr key={ele.id}>
                          <td>
                            <Field
                              type="checkbox"
                              name="checked"
                              value={ele.ECC_KTOKD}
                            />
                          </td>
                          {Object.values(ele)
                            .filter((x) => x !== ele["EXTERNIND"])
                            .map((item) => {
                              return (
                                <td key={item}>
                                  <label>{item}</label>
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <button
                type="submit"
                className={classes["select-button"]}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting...." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      ) : (
        <div className={classes.load}>Loading....</div>
      )}
    </div>
  );
}
