import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "./overlay/Overlay";
import MultiSelect from "./multi-select/MultiSelect";
import { Call } from "../utils/api";

export default function ProductSelection() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const options = ["1710", "201", "202", "3910", "1110", "ITNA"];
  const [err, setErr] = useState("");
  const Schema = Yup.object().shape({
    Product: Yup.string().required("Required"),
    ProductType: Yup.string().required("Required"),
    Plant: Yup.array()
      .required("Required")
      .min(1, "Atleast one value should be selected"),
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Formik
        initialValues={{
          Product: "",
          ProductType: "",
          Plant: [],
        }}
        validationSchema={Schema}
        onSubmit={async (values) => {
          // console.log(values);
          setLoading(true);
          try {
            let data = await Call(
              "product_view/",
              "POST",
              JSON.stringify(values)
            );
            setLoading(false);
            navigate("/product/view", {
              state: ["product", data],
            });
          } catch (res) {
            console.log(res);
            setErr("Error occurred");
            setLoading(false);
          }
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className="main">
            <label>Product :</label>
            <Field name="Product" />
            {errors.Product && touched.Product ? (
              <div className="error">{errors.Product}</div>
            ) : null}
            <label>ProductType :</label>
            <Field name="ProductType" />
            {errors.ProductType && touched.ProductType ? (
              <div className="error">{errors.ProductType}</div>
            ) : null}
            <label>Plant</label>
            <MultiSelect
              vals={options}
              fieldName="Plant"
              setValues={setFieldValue}
            />
            {errors.Plant && touched.Plant ? (
              <div className="error">{errors.Plant}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
