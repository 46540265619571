import { API_URL } from "./constants";

export const Call = async (url, method, payload) => {
  let myheaders = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  if (method === "POST") {
    myheaders.body = payload;
  }
  myheaders.method = method;
  let res = await fetch(API_URL + url, myheaders);
  let data = await res.json();
  return data;
};
