import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "./overlay/Overlay";
import { Call } from "../utils/api";

export default function Costcenter() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const Schema = Yup.object().shape({
    CostCenter: Yup.string().required("Required"),
    // Account_Group: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          CostCenter: "",
        }}
        validationSchema={Schema}
        onSubmit={async (values) => {
          // console.log(values);
          setLoading(true);
          try {
            let data = await Call(
              "costcenter_view/",
              "POST",
              JSON.stringify(values)
            );
            setLoading(false);
            navigate("/cost_center/view", {
              state: ["costcenter", data],
            });
          } catch (res) {
            console.log(res);
            setLoading(false);
          }
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>Cost Center ID :</label>
            <Field name="CostCenter" />
            {errors.CostCenter && touched.CostCenter ? (
              <div className="error">{errors.CostCenter}</div>
            ) : null}
            {/* <label>Account Group :</label>
            <Field name="Account_Group" />
            {errors.Account_Group && touched.Account_Group ? (
              <div className="error">{errors.Account_Group}</div>
            ) : null}*/}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
