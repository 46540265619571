import React, { useState } from "react";
import "./index.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "../overlay/Overlay";
import { API_URL } from "../../utils/constants";
import { Call } from "../../utils/api";

export default function Characteristic() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const Schema = Yup.object().shape({
    Characteristic: Yup.string().required("Required"),
    // CharacteristicType: Yup.string().required("Required"),
    // Account_Group: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          Characteristic: "",
          // CharacteristicType: "",
        }}
        validationSchema={Schema}
        onSubmit={async (values) => {
          console.log(values);
          setLoading(true);
          try {
            let data = await Call("char_view/", "POST", JSON.stringify(values));
            setLoading(false);
            navigate("/product/view", {
              state: ["CharcInternalID", data],
            });
          } catch (res) {
            console.log(res);
            setLoading(false);
          }
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>Characteristic ID :</label>
            <Field name="Characteristic" />
            {errors.Characteristic && touched.Characteristic ? (
              <div className="error">{errors.Characteristic}</div>
            ) : null}
            {/* <label>Characteristic Type :</label>
            <Field name="CharacteristicType" />
            {errors.CharacteristicType && touched.CharacteristicType ? (
              <div className="error">{errors.CharacteristicType}</div>
            ) : null} */}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
